import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const width = 350;

const CollabPage = () => {
	return (
		<Layout>
			<Seo title="Collaborations" />
			<h2>Collaborations</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/_MG_1247.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h3>Collabs</h3>
			<p>
		     Here are some collaborations I've had the pleasure of participating in;
			 they are in reverse chronological order.
			 Do also check out my <Link to="/music/">music page</Link>.<br />
			 <Link to="/contact/">Contact me</Link> if you wanna work together!

		    </p>

			<h4>
				Caliandú—Festa pra Dois—2022
			</h4>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/collab artwork/JRCOLLAB06 caliandu - festa pra dois.jpg"
				alt="Caliandú - Festa pra Dois - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={width}
				/>
			</div>
			<p>
				I mastered this 2022 single from Caliandú via my friend Gustavo Halfeld who mixed it.
				It's a pretty cool track and I enjoyed accompanying it that final step toward the listening world.
				I actually did my part back in 2021, I think... it took quite some time for this one to make its way out.
				Worth the wait, though 🙂
			</p>
			<p>
				<a href="//open.spotify.com/album/26KfHHjplsEPc5ElcKJ0Rs?si=j91NnE8URjKPNYqnb3ekRQ" title="Caliandú - Festa pra Dois - 2021">
					Listen at Spotify
				</a>
			</p>

			<h4>
				Tico de Moraes, Jesse Rivest—Wonderful Words—2022
			</h4>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/collab artwork/JRCOLLAB05 wonderful words.jpg"
				alt="Tico de Moraes, Jesse Rivest - Wonderful Words - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={width}
				/>
			</div>
			<p>
				Just as with <span className="italic">Fettering Chance</span>, Tico and I
				wrote, arranged, recorded, and produced this song together, fifty-fifty.
				This time we released it with both of us as primary artists;&nbsp;
				<span className="italic">Fettering Chance</span> was released with Tico as the primary artist
				and myself as a featured artist. That was, in part, my lack of understanding of that aspect of distribution.
			</p>
			<p>
				<span className="italic">Wonderful Words</span> is our second songwriting/production collaboration together
				and we were both just as excited about it as the we were the first one; perhaps even more so!
				On this recording,
				Tico sings and plays his seven-string jazz guitar, plus adds several extra jazzy, electric guitars;
				he also provides a rich layer of harmonizing vocals in the choruses.
				I also sing and I play acoustic steel-string guitar, a synth-and-electric-guitar tremelo vibe, plus the electric guitar solo.
				Tico mixed the track and I mastered it.
				As with <span className="italic">Fettering Chance</span>, Tico got our friend Misael Barros to do some fine drumming;
				this time he also got Alexander Raichenok to play piano/keys.
				We hope you enjoy it!
			</p>
			<p>
				<a href="//open.spotify.com/album/7ivBZYLmSa4YnlFJVElIWr?si=TivVR7gbReCDYkhhEcqhxw" title="Tico de Moraes - Wonderful Words - 2022">
					Listen at Spotify
				</a>
			</p>
			<p>
				NOTABLE: On April 4, 2022, <span className="italic">Wonderful Words</span> was added to Spotify's editorial playlist,
				Vocal Jazz, where it stayed for 11 months!
			</p>

			<h4>
				Pacífico—Olhos Abertos—2021
			</h4>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/collab artwork/JRCOLLAB04 pacifico - olhos abertos.jpg"
				alt="Pacífico - Olhos Abertos - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={width}
				/>
			</div>
			<p>
				I mastered this 2021 EP from Pacífico, a colleague and friend-of-a-friend in Brasília.
				The mutual friend is Gustavo Halfeld, who engineered and mixed the album and connected with me for the mastering;
				this was around the same time that Pacífico did the artwork for my single,&nbsp;
				<Link to="/music/06/">Summer Grace</Link>.
				I really liked the engineering of the album as well as Lucas' (Pacífico) artistry in his music.
			</p>
			<p>
				<a href="//open.spotify.com/album/5HOPXXGk7Q9r4n2zTYTXW2?si=Mlzp0yZXR12tdmMwBZrDGg" title="Pacífico - Olhos Abertos - 2021">
					Listen at Spotify
				</a>
			</p>

			<h4>
				Tico de Moraes—Fettering Chance—2021
			</h4>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/collab artwork/JRCOLLAB03 fettering chance front.png"
				alt="Tico de Moraes - Fettering Chance - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={width}
				/>
			</div>
			<p>
				This is a single that Tico released under his artist entity;
				we wrote, arranged, and produced the song and recording together, fifty-fifty.
				This was my first ever songwriting/production collaboration and we were both very stoked about;
				Tico became one of my first musical friends after I arrived in Brasilia, the capital of Brazil;
				our friendship reached a new, exciting level upon this project's arrival nearly eight years later.
				Tico sings first and plays a seven-string jazz guitar.
				I come in singing next while playing a tremolo electric guitar.
				I also provided the background vocals in the choruses.
			</p>
			<p>
				<a href="//open.spotify.com/track/0zWQy8Zwf0vaokT3PvBc3Z?si=a452a678dd9b4693" title="Tico de Moraes - Fettering Chance - 2021">
					Listen at Spotify
				</a>
			</p>

			<h4>
				Tessa Rain—Dirt Poems—2010
			</h4>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/collab artwork/JRCOLLAB02 dirt poems front.jpg"
				alt="Tessa Rain - Dirt Poems - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={width}
				/>
			</div>
			<p>
				I believe many folks in New Zealand had been waiting for Tessa to make her own solo album;
				she had been collaborating with others for many years.
				I was excited to have a part in her project, as I had known her songs in more intimate, rawer forms for quite a while.
				This project was produced by Age Pryor, one of Tessa's longtime collaborators, at Lee Prebble's studio, The Surgery.
				I found the whole experience to be quite fun!
				Robin Hinkley and I performed backing vocals on <span className="italic">Toaster</span>;
				I performed the whale-sound slide guitar on <span className="italic">Circadian Rhythm</span>.
				Ultimately, I think my favourite two songs on this album are <span className="italic">Big Bang Theory</span> and <span className="italic">Town For You</span>.
			</p>
			<p>
				<a href="//open.spotify.com/album/5NApe7dDV8uc87ztyZ38DD?si=5atoeZ_1R42KcYLDzDfrCg&dl_branch=1" title="Tessa Rain - Dirt Poems - 2010">
					Listen at Spotify
				</a>
			</p>

			<h4>
				Niels Gedge—Between Two Lines—2010
			</h4>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/collab artwork/JRCOLLAB01 between two lines front.jpg"
				alt="Niels Gedge - Between Two Lines - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={width}
				/>
			</div>
			<p>
				Niels is one of the kindest, warmest, inclusive souls I had the fortune of mingling with in New Zealand.
				He has a wonderful, deep voice; he plays a lovely baritone guitar; and he writes some lovely songs.
				I have some fond memories of sitting about the fire and sipping scotch with my friend, Niels.
				When this project came about, I was very happy to play a part and quite keen to try out some tremolo guitar.
				That's exactly what I did on <span className="italic">When I'm With You</span>.
				I remember being quite fond of another track, <span className="italic">Whanganui</span>.
			</p>
			<p>
				<a href="//open.spotify.com/album/63oFv7LnZtoc6ZbNp5Qkrm?si=SZ6UXGMqSdCcTfqL_Q1H4w&dl_branch=1" title="Niels Gedge - Between Two Lines - 2010">
					Listen at Spotify
				</a>
			</p>

		</Layout>
	);
}

export default CollabPage;
